<template>
  <el-select class="user-chose-select" :value="hasSelectUser" collapse-tags  multiple placeholder="请选择">
    <div v-loading="loading" class="select-options-container" slot="empty">
      <div class="search-input">
        <el-input v-model="searchVal" @input="handleInputChange" size="mini" placeholder="搜索..." clearable>
        </el-input>
      </div>
      <div class="select-options-warp">
        <el-tree
          :expand-on-click-node="false"
          :ref="(el) => { treeRef = el }"
          :filter-node-method="filterNode"
          default-expand-all
          :data="list"
          :props="defaultProps"
          @node-click="handleNodeClick"
        >
          <p class="user-chose-info" slot-scope="{ node, data }">
            <span v-if="value.includes(data.id)">
               <svg width="16" height="16">
                <use xlink:href="#iconfuxuan-quanxuan-zhengchangxuanting" />
              </svg>
            </span>
            &nbsp;&nbsp;
            <span>{{ node.label }}</span>
          </p>
        </el-tree>
      </div>
    </div>
  </el-select>
</template>
<script>
import { defineComponent, ref, computed } from 'vue'
import { mapi } from '@/saas-apis/konwledgeBase.js'
import { Tree, Message } from 'element-ui'
export default defineComponent({
  components: {
    'el-tree': Tree
  },
  props: {
    value: {
      type: Array,
      default() {
        return []
      }
    }
  },
  setup(props, ctx) {
    const list = ref([])
    const nameHash = ref([])
    const loading = ref(false)
    const getList = () => {
      const REQDATA = {
        object_uuid:'object6316bf42c0f62',
        view_uuid:'view6316e6ca20200',
        __method_name__: 'dataList',
        transcode: 0,
      }
      loading.value = true
      mapi(REQDATA).then(res => {
        if(res.data.code === 200) {
          const data = res.data.data
          const handle = (data) => {
            data.map(el => {
              nameHash.value.push({id: el.id, name: el.name })
              if(Array.isArray(el.children) && el.children.length) {
                handle(el.children)
              }
            })
          }
          if(Array.isArray(data)) {
            handle(data)
            list.value = data
          }
        } else {
          Message.error(res.data.msg)
        }
      }).catch(err => {
         Message.error(err)
      }).finally(() => {
        loading.value = false
      })
    }
    getList()
    const hasSelectUser = computed(() => {
      const data = props.value.map(el => {
        return nameHash.value.find(ele => ele.id === el)?.name || ''
      })
      return data
    })
    const defaultProps = {
      children: 'children',
      label: 'name'
    }
    const handleNodeClick = (data) => {
      if(data.id) {
        let res = []
        const index = props.value.findIndex(el => el === data.id)
        if(index > -1) {
          res = props.value.slice()
          res.splice(index, 1)
        } else {
          res = props.value.slice()
          res.push(data.id)
        }
        ctx.emit('input', res)
      }
    }
    const treeRef = ref(null)
    const searchVal = ref('')
    let timer = null
    const handleInputChange = () => {
      if(timer) {
        clearTimeout(timer)
      }
      timer = setTimeout(() => {
        if(treeRef.value) {
          treeRef.value.filter(searchVal.value)
          timer = null
        }
      }, 1000)
    }
    const filterNode = (value, data) => {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    }
    return {
      list,
      defaultProps,
      hasSelectUser,
      handleNodeClick,
      searchVal,
      handleInputChange,
      filterNode,
      treeRef,
      loading,
      nameHash
    }
  },
})
</script>
<style lang="less" scoped>
  :deep(.el-tag__close) {
    display: none;
    &::before{
      display: none;
    }
  }
  .select-options-container{
    padding: 5px;
    .search-input{
      padding: 4px;
      border-bottom: 1px solid #f1f1f1;
    }
    .select-options-warp{
      max-height: 200px;
      overflow-y: auto;
      .user-chose-info{
        display: flex;
        align-items: center;
      }
    }
  }
  
</style>