<!--
 * @Author: zx
 * @Date: 2022-06-15 09:41:28
 * @LastEditTime: 2022-10-26 15:14:56
 * @Description:
 * @LastEditors: 朱廷果 1028509503@qq.com
-->
<template>
  <div class="mobile">
    <div class="mobile-header">
      <el-button type="primary" @click="onCreateIcon()"> 添加功能图标 </el-button>
      <el-button type="primary" @click="onShowIcon()"> {{ showIconListVisible ? '切换功能列表' : '切换图标列表' }} </el-button>
    </div>
    <div class="mobile-content">
      <article v-if="showIconListVisible" v-loading="iconLoading" class="drawer">
        <div class="drawer-item" v-for="(item, index) in funcIconsCopy" :key="'icon' + index">
          <div class="drawer-item-left">
            <svg style="width: 30px;height: 30px;" class="iconfont-svg" aria-hidden="true" v-if="item.icon">
              <use :xlink:href="'#' + item.icon" />
            </svg>
            <span style="padding-left: 15px">{{ item.name }}</span>
          </div>
          <div class="drawer-item-right">
            <span style="color: #409eff" @click="openIconEdit(item)">
              编辑
            </span>
            <span style="color: #F56C6C;" @click="onRemove(item)">
              删除
            </span>
          </div>
        </div>
      </article>
      <article v-else class="list">
        <section class="app-item" v-for="item in mobileAppList" :key="item.id">
          <svg aria-hidden="true" class="iconfont-svg">
            <use :xlink:href="'#' + item.icon" />
          </svg>
          <div class="info">
            <h4 class="name">{{ item.name }}</h4>
            <p v-if="item.type === '1'" class="desc">{{ item.page_uuid }}</p>
            <p v-if="item.type === '2'" class="desc">{{ item.routerPath }}</p>
          </div>
          <!-- 操作 -->
          <div class="item-actions">
            <i class="iconfont iconbianji2 modify" @click="onModify(item)"></i>
            <i class="iconfont icongis_shanchu delete" @click="onDelete(item)"></i>
          </div>
        </section>
        <!-- 创建应用 -->
        <section class="app-item create-app" @click="onCreateFunc()">
          <div class="info">
            <i class="el-icon-plus plus-btn"></i>
            <h4 class="name">创建一个功能</h4>
          </div>
        </section>
      </article>
    </div>

		<!-- 创建 -->
		<el-dialog
			:title="createFormType ? '编辑' : '新增功能'"
			:visible.sync="showAdd"
			center
      width="50%"
		>
			<el-form
				:model="createForm"
				label-width="120px"
				ref="createForm"
				:rules="rules"
			>
				<el-form-item
					label="功能名称"
					prop="name"
				>
					<el-input
						v-model="createForm.name"
						placeholder="请输入功能名称"
						clearable
					></el-input>
				</el-form-item>
				<el-form-item label="功能图标" prop="icon">
					<el-select
						v-model="createForm.icon"
						allow-create
						filterable
            popper-append-to-body
						default-first-option
						placeholder="请选择功能图标"
						clearable
					>
						<el-option
							v-for="item in funcIcons"
							:key="item.id"
              :label="item.name"
							:value="item.icon"
						>
							<div class="icon-option">
                <svg style="width: 30px;height: 30px;" class="iconfont-svg" aria-hidden="true" v-if="item.icon">
                    <use :xlink:href="'#' + item.icon" />
                </svg>
								<span style="padding-left: 15px">{{ item.name }}</span>
							</div>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item
					label="权限"
					prop="name"
				>
					<el-input
						v-model="createForm.permissions"
						placeholder="请输入权限"
						clearable
					></el-input>
				</el-form-item>
				<el-form-item label="架构" prop="icon">
					<el-select
            multiple
						v-model="createForm.archiType"
						placeholder="请选择显示架构"
					>
						<el-option label="公司" value="company"></el-option>
						<el-option label="项目" value="project"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="功能类型" prop="icon">
					<el-select
						v-model="createForm.type"
						placeholder="请选择功能类型"
					>
						<el-option label="配置页面" value="1"></el-option>
						<el-option label="固定页面" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-if="createForm.type === '1'" label="页面" prop="page">
					<el-select
						v-model="createForm.page_uuid"
						allow-create
						filterable
            popper-append-to-body
						default-first-option
						placeholder="请选择功能页面"
						clearable
					>
						<el-option
							v-for="item in pagesList"
							:key="item.name"
              :label="item.name"
							:value="item.uuid"
						>
              <span>
                {{ item.name }}
              </span>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item
          v-if="createForm.type === '2'"
					label="固定页面路由"
					prop="name"
				>
					<el-input
						v-model="createForm.routerPath"
						placeholder="请输入固定页面路由"
						clearable
					></el-input>
				</el-form-item>
				<el-form-item
          v-if="createForm.type === '2'"
					label="路由携带参数"
					prop="params"
				>
					<div class="params">
            <div class="params-list" v-for="(item, index) in createForm.params" :key="'params' + index">
              <div class="params-item">
                <el-input
                  v-model="item.key"
                  placeholder="请输入参数key值"
                  clearable
                ></el-input>
                <el-input
                  v-model="item.value"
                  placeholder="请输入参数value值"
                  clearable
                ></el-input>
              </div>
              <div class="params-del">
                <i class="iconfont iconwangpan-shanchu1x" @click="onRemoveParams(index)"></i>
              </div>
            </div>
            <div>
              <el-button type="primary" @click="addParams()"> 新增携带参数 </el-button>
            </div>
          </div>
				</el-form-item>
			</el-form>
			<span
				slot="footer"
				class="dialog-footer"
			>
				<el-button @click="onReset">取 消</el-button>
				<el-button
					type="primary"
					@click="onConfirmCreate"
				>确定</el-button>
			</span>
		</el-dialog>
		<!-- 创建 -->
		<el-dialog
			:title="iconForm.id ? '编辑图标' : '新增图标'"
			:visible.sync="showAddIcon"
			center
      width="30%"
		>
			<el-form
				:model="iconForm"
				label-width="120px"
				ref="icon"
				:rules="iconRules"
			>
				<el-form-item
					label="图标名称"
					prop="name"
				>
					<el-input
						v-model="iconForm.name"
						placeholder="请输入图标名称"
						clearable
					></el-input>
				</el-form-item>
				<el-form-item
					label="图标字符"
					prop="icon"
				>
					<el-input
						v-model="iconForm.icon"
						placeholder="请输入图标字符"
						clearable
					></el-input>
				</el-form-item>
			</el-form>
			<span
				slot="footer"
				class="dialog-footer"
			>
				<el-button @click="onResetIcon">取 消</el-button>
				<el-button
					type="primary"
					@click="onConfirmCreateIcon"
				>确定</el-button>
			</span>
		</el-dialog>
  </div>
</template>
<script>
import { dataInterface } from '@/apis/data/index';
export default {
  components: {},
  props: {},
  data() {
    return {
      showAddIcon: false,
      showIconListVisible: false,
      showAdd: false,
      funcIcons: [], // 图标列表
      funcIconsCopy: [],
      pagesList: [], // 移动端配置好的页面列表
      mobileAppList: [], // 移动端功能应用列表
			// 验证规则
			rules: {
				name: [
					{ required: true, message: '请输入功能名称', trigger: 'blur' },
					{
						min: 1,
						max: 10,
						message: '长度在 1 到 10 个字符',
						trigger: 'blur'
					}
				],
				icon: [
					{ required: true, message: '请选择功能图标', trigger: 'blur' }
				],
				page_uuid: [
					{ required: true, message: '请选择页面', trigger: 'blur' }
				]
			},
      createForm: {
        name: '',
        icon: '',
        page_uuid: '',
        type: '1',
        routerPath: '',
        permissions: '',
        params: [],
        archiType: []
      },
      /* 图标新增 */
      iconForm: {
        name: '',
        icon: ''
      },
			// 验证规则
			iconRules: {
				name: [
					{ required: true, message: '请输入图标名称', trigger: 'blur' },
					{
						min: 1,
						max: 10,
						message: '长度在 1 到 10 个字符',
						trigger: 'blur'
					}
				],
				icon: [
					{ required: true, message: '请输入图标字符', trigger: 'blur' }
				]
			},
      createFormType: false, // 是否是编辑
      iconLoading: false
    };
  },
  mounted() {
    this.getCreateIcon()
    this.getPagesList()
    this.getCreateFuncList()
  },
  methods: {
    onCreateFunc() {
      this.createForm = {
        name: '',
        icon: '',
        page_uuid: '',
        data_id: null,
        type: '2',
        permissions: '',
        routerPath: '',
        params: [],
        archiType: []
      }
      this.createFormType = false
      this.showAdd = true
      this.getCreateIcon()
    },
    onReset() {
      this.showAdd = false
      this.createForm = {
        name: '',
        icon: '',
        page_uuid: '',
        type: '1',
        routerPath: '',
        permissions: '',
        params: [],
        archiType: []
      }
      this.createFormType = false
    },
    onConfirmCreate() {
      console.log(this.createForm, '点击了提交功能新增')
      if (
        !this.createForm.name ||
        !this.createForm.icon ||
        (this.createForm.type === '1' && !this.createForm.page_uuid) ||
        (this.createForm.type === '2' && !this.createForm.routerPath)
      ) {
        this.$message.error('请填写完整功能信息！')
        return
      }
      const params = {
				object_uuid: 'object62a980ced37a6',
				view_uuid: 'view62a98303cabba',
				__method_name__: 'createData',
				transcode: 0,
        ...this.createForm
			}
      if (this.createFormType) {
        params.__method_name__ = 'updateData'
        params.data_id = this.createForm.data_id
      }
			dataInterface(params).then((res) => {
        if (res.status === 200 && res.data && res.data.code == 200) {
          this.createForm = {
            name: '',
            icon: '',
            page_uuid: '',
            type: '1',
            routerPath: '',
            permissions: '',
            params: [],
            archiType: []
          }
          this.$message.success(this.createFormType? '编辑成功' : '新增成功！')
          this.getCreateFuncList()
          this.showAdd = false
          this.createFormType = false
        }
      }).catch((err) => {
        console.log(err, '----err');
      });
    },
    getCreateFuncList() {
			dataInterface({
				object_uuid: 'object62a980ced37a6',
				view_uuid: 'view62a98303cabba',
				__method_name__: 'dataList',
				transcode: 0,
        size:9999
			}).then((res) => {
        console.log(res)
        if (res.status === 200 && res.data && res.data.code == 200) {
          this.mobileAppList = res.data.data.data
        }
      }).catch((err) => {
        console.log(err, '----err');
      });
    },
    getCreateIcon() {
      this.iconLoading = true
			dataInterface({
				object_uuid: 'object62a9439b0430a',
				view_uuid: 'view62a9440d8d4e6',
				__method_name__: 'dataList',
				transcode: 0,
        size:9999
			}).then((res) => {
        this.iconLoading = false
        if (res.status === 200 && res.data && res.data.code == 200) {
          console.log(res.data.data, '请求图标数据')
          this.funcIcons = res.data.data.data
          this.funcIconsCopy = JSON.parse(JSON.stringify(this.funcIcons))
          if (this.funcIconsCopy && this.funcIconsCopy.length) {
            this.funcIconsCopy.forEach(element => {
              element.editVisible = false
            });
          }

        }
      }).catch((err) => {
        console.log(err, '----err');
      });
    },
    getPagesList() {
			dataInterface({
				object_uuid: "a4f016d6-c602-4492-8874-f088c3c0b3b9",
        page: 1,
        search: [{field_uuid: "field61bc41f645e17", ruleType: "like", value: "mobile"}],
        size: 20,
        transcode: 0,
        view_uuid: "view61922b1881103",
        __method_name__: "dataList"
			}).then((res) => {
        if (res.status === 200 && res.data && res.data.code == 200) {
          this.pagesList = res.data.data.data
        }
      }).catch((err) => {
        console.log(err, '----err');
      });
    },
    onCreateIcon() {
      this.iconForm = {
        name: '',
        icon: ''
      }
      this.showAddIcon = true
    },
    onShowIcon() {
      if (this.showIconListVisible) {
        this.funcIconsCopy = []
        this.showIconListVisible = false
        return
      }
      this.funcIconsCopy = JSON.parse(JSON.stringify(this.funcIcons))
      if (this.funcIconsCopy && this.funcIconsCopy.length) {
        this.funcIconsCopy.forEach(element => {
          element.editVisible = false
        });
      }
      this.showIconListVisible = true
    },
    onResetIcon() {
      this.showAddIcon = false
      this.iconForm = {
        name: '',
        icon: ''
      }
    },
    onConfirmCreateIcon() {
      if (!this.iconForm.name || !this.iconForm.icon) {
        this.$message.error('数据未填写完整！');
        return
      }
      const params = {
				object_uuid: 'object62a9439b0430a',
				view_uuid: 'view62a9440d8d4e6',
				__method_name__: 'createData',
				transcode: 0,
        name: this.iconForm.name,
        icon: this.iconForm.icon,
			}
      if (this.iconForm.id) {
        params.__method_name__ = 'updateData'
        params.data_id = this.iconForm.id
      }
			dataInterface(params).then((res) => {
        if (res.status === 200 && res.data && res.data.code == 200) {
          this.showAddIcon = false
          this.iconForm = {
            name: '',
            icon: ''
          }
          this.getCreateIcon()
          this.$message.success('新增成功！')
        }
      }).catch((err) => {
        console.log(err, '----err');
      });
    },
    onModify(item) {
      this.createForm = {
        name: item.name,
        icon: item.icon,
        page_uuid: item.page_uuid,
        data_id: item.id,
        type: item.type,
        permissions: item.permissions,
        routerPath: item.routerPath,
        params: item.params,
        archiType: item.archiType
      }
      this.createFormType = true
      this.showAdd = true
    },
    onDelete(item) {
      this.$confirm(`是否确认删除当前功能?`).then(() => {
        dataInterface({
          object_uuid: 'object62a980ced37a6',
          view_uuid: 'view62a98303cabba',
          __method_name__: 'deleteData',
          transcode: 0,
          data_id: item.id
        }).then((res) => {
          if (res.status === 200 && res.data && res.data.code == 200) {
            this.getCreateFuncList()
            this.$message.success('删除成功！')
          }
        }).catch((err) => {
          console.log(err, '----err');
        });
			}).catch(() => {});
    },
    onRemoveParams(index) {
      this.createForm.params.splice(index, 1)
    },
    addParams() {
      this.createForm.params.push({
        key: '',
        value: ''
      })
    },
    /* 编辑图标 */
    openIconEdit(data) {
      console.log(data, '编辑数据')
      this.iconForm = data
      this.showAddIcon = true
    },
		onRemove(item) {
      this.$confirm(`是否确认删除当前图标?`).then(() => {
        dataInterface({
          object_uuid: 'object62a9439b0430a',
          view_uuid: 'view62a9440d8d4e6',
          __method_name__: 'deleteData',
          transcode: 0,
          data_id: item.id
        }).then((res) => {
          if (res.status === 200 && res.data && res.data.code == 200) {
            this.getCreateIcon()
            this.$message.success('删除成功！')
          }
        }).catch((err) => {
          console.log(err, '----err');
        });
			}).catch(() => {});
		},
  },
};
</script>
<style lang='less' scoped>
.mobile {
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  .mobile-header {
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px 0 20px;
    text-align: right;
    background-color: #f5f5f5;
  }
  .mobile-content {
    flex: 1;
    box-sizing: border-box;
    background: #f5f5f5;
    overflow-y: auto;
    .list{
      flex: 1;
      width: 100%;
      overflow: hidden;
      overflow-y: auto;
      display: flex;
      align-content: flex-start;
      box-sizing: border-box;
      padding: 10px;
      flex-wrap: wrap;
      .app-item{
        margin: 10px;
        position: relative;
        width: 235px;
        height: 80px;
        overflow: hidden;
        display: flex;
        box-sizing: border-box;
        padding: 16px;
        border-radius: 5px;
        background: #fff;
        cursor: pointer;
        box-shadow: 0 3px 10px 0 rgba(31, 35, 41, 0.04);
        &:hover{
          box-shadow: 0px 10px 10px 3px rgba(31, 35, 41, 0.1);
          .item-actions{
            top: 0;
          }
        }
        .iconfont-svg{
          width: 42px;
          height: 42px;
        }
        .info{
          flex: 1;
          box-sizing: border-box;
          padding-left: 8px;
          .name{
            width: 100%;
            margin-top: 4px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #1f2329;
            left: 20px;
            text-align: left;
          }
          .desc{
            width: 100%;
            margin-top: 6px;
            height: 18px;
            font-size: 12px;
            line-height: 18px;
            color: #8f959e;
            word-break: break-all;
            overflow: hidden;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
            display: -webkit-box!important;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            text-align: left;
          }
        }
        .item-actions{
          display: flex;
          position: absolute;
          top: 82px;
          left: 0;
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          background: rgba(0, 0, 0, .5);
          transition: all .2s linear;
          align-items: center;
          justify-content: center;
          .iconfont{
            text-align: center;
            flex: 1;
            line-height: 14px;
            font-size: 20px;
            color: #fff;
            font-weight: bold;
            &.delete{
              color: @dangerColor;
            }
            &.set-up{
              color: @theme;
            }
          }
        }
        &.create-app{
          .info{
            margin: 0!important;
            text-align: center;
            .name{
              color: @theme;
              background: #fff;
            }
            .name,.desc{
              text-align: center;
            }
            .plus-btn{
              font-size: 24px;
              line-height: 30px;
              color: @theme;
            }
          }
        }
      }
    }
  }
  .params {
    width: 100%;
    height: auto;
    overflow-x: hidden;
    .params-list {
      display: flex;
      justify-content: space-between;
      .params-item {
        width: 50%;
        height: 70px;
        margin: 10px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
      }
      .params-del {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 10px 0;
        box-sizing: border-box;
        padding-left: 30px;
        i {
          color: #F56C6C;
        }
      }
    }
  }
}
.icon-option {
  display: flex;
  align-items: center;
}
.drawer {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 10px;
  .drawer-item {
    width: 100%;
    height: 50px;
    display: flex;
    border-bottom: 1px solid #d7d7d7;
    div {
      display: flex;
      align-items: center;
    }
    .drawer-item-left {
      width: 70%;
      justify-content: flex-start;
    }
    .drawer-item-right {
      width: 30%;
      justify-content: flex-end;
      span {
        margin-right: 4px;
      }
    }
  }
}
</style>
