<template>
  <div class="richtext-editor-container">
    <div class="richtext-editor-warp">
      <Editor
        :init="setting"
        :value="value"
        @input="handleInput"
        />
    </div>
  </div>
</template>
<script>
import Editor from '@tinymce/tinymce-vue'
import { defineComponent, ref } from 'vue'
import {formRequest} from "@/apis/data/form";
import { Scrollbar } from 'element-ui'
import style from '@/manage-views/views/knowledgeBase/components/richText/style.js'
import 'tinymce/plugins/imagetools' // 图片工具
export default defineComponent({
  components: {
    Editor,
    Scrollbar
  },
  props: {
    value: {
      type: String,
      default: '',
      require: true
    }
  },
  setup(props, ctx) {
    const setting = ref({
      language: 'zh_CN', // 中文需要请求
      menubar: false, // 禁用菜单栏
      auto_focus: true, // 自动获取焦点
      plugins: 'print preview searchreplace fullscreen autolink image imagetools link media code codesample table charmap hr pagebreak nonbreaking advlist lists textpattern help paste',
      toolbar: `fullscreen
      | undo redo removeformat
      | formatselect fontselect fontsizeselect lineheight
      | bold italic underline strikethrough link autolink
      | forecolor backcolor 
      | alignment
      | bullist numlist
      | blockquote subscript superscript
      | image media codesample table hr insertdatetime preview code searchreplace visualblocks 
      | dagang`,
      toolbar_groups: {
        alignment: {
            icon: 'align-left',
            tooltip: 'alignment',
            items: 'alignleft aligncenter alignright alignjustify outdent indent',
        },
      },
      placeholder: '请输入正文',
      branding: false,
      elementpath: false,
      statusbar: false,
      autosave_ask_before_unload: false,
      content_style: style,
      formats: {
        h1: { block: 'h1', classes: 'aiming-point' },
        h2: { block: 'h2', classes: 'aiming-point' },
        h3: { block: 'h3', classes: 'aiming-point' },
        h4: { block: 'h4', classes: 'aiming-point' },
        h5: { block: 'h5', classes: 'aiming-point' }
      },
      font_formats: '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;' +
      '苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;' +
      'Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;' +
      'Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;' +
      'Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;' +
      'Terminal=terminal,monaco;Times New Roman=times new roman,times;Verdana=verdana,geneva;Webdings=webdings;' +
      'Wingdings=wingdings,zapf dingbats;' +
      '知乎配置=BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, ' +
      'Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;' +
      '小米配置=Helvetica Neue,Helvetica,Arial,Microsoft Yahei,Hiragino Sans GB,Heiti SC,WenQuanYi Micro Hei,sans-serif',
      fontsize_formats: '12px 14px 16px 18px 24px 36px 48px 56px 72px',
      // 图片上传
      images_upload_handler: function (blobInfo, success, failure) {
        if (blobInfo.blob().size / 1024 / 1024 > 5) {
          failure('上传失败，图片大小请控制在 5M 以内')
        } else {
          const data = new FormData()
          data.append('file', blobInfo.blob())
          const headers = {
            'Content-Type': 'multipart/form-data',
            metadatatoken: process.env.VUE_APP_METATOKEN
          }
          formRequest('post','/api/mapi?__method_name__=file', data, headers).then(res => {
            if (res.data.code === 200) {
              success(res.data.data.url.replace('_thumb', ''))
            } else {
              failure('上传失败')
            }
          })
        }
      },
      paste_data_images: true, // 能否粘贴dataUrl图片
      imagetools_cors_hosts: ['dwh_api.bimcc.net'],
    })
    const handleInput = (res) => {
      ctx.emit('input', res)
    }
    return {
      handleInput,
      setting,
    }
  }
})
</script>

<style lang="less" scoped>
  .richtext-editor-container{
    text-align: left;
    width: 100%;
    height: 100%;
    position: relative;
    .richtext-editor-warp{
      background: none;
      width: 100%;
      height: 100%;
      :deep(.tox-tinymce) {
        height: 100% !important;
        .tox-toolbar__primary{
          justify-content: center;
        }
      }
    }
  }
</style>