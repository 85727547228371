<!--
 * @Description: 应用列表
 * @Author: luocheng
 * @Date: 2022-03-14 11:08:39
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2022-12-13 11:30:14
-->
<template>
  <div class="app-list">
    <article class="list" v-loading="loading">
      <section class="app-item" v-for="item in appList" :key="item.id">
        <div class="iconfont-div" :style="'background:' + (item.background && item.background.indexOf('linear') !== -1 ? item.background : '')">
          <svg aria-hidden="true" class="iconfont-svg" >
            <use :xlink:href="'#' + item.icon" />
          </svg>
        </div>

        <div class="info">
          <h4 class="name">{{ item.name }}</h4>
          <p class="desc">{{ item.describe }}</p>
        </div>
        <!-- 操作 -->
        <div class="item-actions">
          <i class="iconfont iconbianji2 modify" @click="onModify(item)"></i>
          <i class="iconfont icongis_shanchu delete" @click="onDelete(item)"></i>
          <i class="iconfont iconpeizhi1 set-up" @click="onSetUp(item)"></i>
        </div>
      </section>
      <!-- 创建应用 -->
      <section class="app-item create-app" @click="onModify(null)">
        <div class="info">
          <i class="el-icon-plus plus-btn"></i>
          <h4 class="name">创建一个应用</h4>
          <!-- <p class="desc">根据需求自定义创建一个应用</p> -->
        </div>
      </section>
    </article>
    <!-- 创建应用 -->
    <ModifyApp
      v-if="showModify"
      v-model="showModify"
      :appList="appList"
      :type="modifyType"
      :appData="modifyData"
      @updateList="getList"
    ></ModifyApp>
  </div>
</template>

<script>
import { dataInterface } from '@/apis/data';
import ModifyApp from './ModifyApp';

export default {
  name: 'AppList',
  components: {
    ModifyApp
  },
  data() {
    return {
      appList: [],
      showModify: false,
      modifyType: 0,
      modifyData: null,
      DATABASE: {
        object_uuid: 'object622eefb3ebecc',
        view_uuid: 'view631711bd9282f',
      }
    }
  },
  provide() {
    return {
      DATABASE: this.DATABASE
    }
  },
  created() {
    this.getList();
  },
  methods: {
    /**
     * @desc: 获取列表
     */
    getList() {
      this.loading = true;
      dataInterface({
        __method_name__: 'dataList',
        ...this.DATABASE,
        transcode: 0,
        size:9999
      }).then(res => {
        if (res.status === 200) {
          const appList = res?.data?.data?.data|| [];
          appList.forEach(item=>{
            if(item.icon.includes('#')){
              item.icon = item.icon.split('#')[1]
            }
            // todo 需要一个默认图标？
            // if(!item.icon.includes('icon')){
            //   item.icon = 'icon-a-05jiluwendang'
            // }
          })
          this.appList = appList.filter(ele => {
            return ele.level === 1;
          })
        }
        this.loading = false;
      }).catch(err => {
        console.log(err);
        this.$message.error('获取数据失败！');
        this.loading = false;
      });
    },
    /**
     * @desc: 配置
     * @param {Object} item
     */
    onSetUp(item) {
      this.$router.push({
        path: '/config-application',
        query: {
          id: item.id
        }
      });
    },
    /**
     * @desc: 编辑
     * @param {Object/null} 选择的数据
     */
    onModify(item) {
      this.modifyType = item ? 1 : 0;
      this.modifyData = item;
      this.showModify = true;
    },
    /**
     * @desc: 删除数据
     * @param {Object} item app数据
     */
    onDelete(item) {
      this.$confirm(`是否确认删除应用【${item.name}】？`).then(() => {
        dataInterface({
          __method_name__: 'deleteData',
          ...this.DATABASE,
          data_id: item.id,
        }).then(res => {
          if (res.status === 200) {
            this.$message.success('操作成功！');
            this.getList();
          } else {
            this.$message.error('操作失败！');
          }
          // this.loading = false;
        }).catch(err => {
          console.log(err);
          this.$message.error('操作失败！');
        });
      }).catch(() => { this.loading = false;})
    }
  }
}
</script>

<style lang="less" scoped>
@itemWidth: 236px;
@itemHeight: 80px;
.app-list{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .list{
    flex: 1;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    align-content: flex-start;
    background: #f5f5f5;
    box-sizing: border-box;
    padding: 10px;
    flex-wrap: wrap;
    .app-item{
      margin: 10px;
      position: relative;
      width: 236px;
      height: 80px;
      overflow: hidden;
      display: flex;
      box-sizing: border-box;
      padding: 16px;
      border-radius: 5px;
      background: #fff;
      cursor: pointer;
      box-shadow: 0 3px 10px 0 rgba(31, 35, 41, 0.04);
      &:hover{
        box-shadow: 0px 10px 10px 3px rgba(31, 35, 41, 0.1);
        .item-actions{
          top: 0;
        }
      }
      .iconfont-div{
        width: 40px;
        height: 40px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        .iconfont-svg{
          width: 30px;
          height: 30px;
        }
      }

      .info{
        flex: 1;
        box-sizing: border-box;
        padding-left: 8px;
        text-align: center;
        .name{
          width: 100%;
          margin-top: 4px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #1f2329;
          left: 20px;
          text-align: left;
        }
        .desc{
          width: 100%;
          margin-top: 6px;
          height: 18px;
          font-size: 12px;
          line-height: 18px;
          color: #8f959e;
          word-break: break-all;
          overflow: hidden;
          -o-text-overflow: ellipsis;
          text-overflow: ellipsis;
          display: -webkit-box!important;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          text-align: left;
        }
      }
      .item-actions{
        display: flex;
        position: absolute;
        top: @itemHeight;
        left: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        background: rgba(0, 0, 0, .5);
        transition: all .2s linear;
        .iconfont{
          text-align: center;
          flex: 1;
          line-height: @itemHeight;
          font-size: 20px;
          color: #fff;
          font-weight: bold;
          &.delete{
            color: @dangerColor;
          }
          &.set-up{
            color: @theme;
          }
        }
      }
      &.create-app{
        .info{
          text-align: center;
          margin: 0!important;
          .name{
            color: @theme;
            background: #fff;
          }
          .name,.desc{
            text-align: center;
          }
          .plus-btn{
            font-size: 24px;
            line-height: 30px;
            color: @theme;
          }
        }
      }
    }
  }
}
</style>
