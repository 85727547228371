<!--
 * @Description: 创建应用
 * @Author: luocheng
 * @Date: 2022-03-14 13:59:42
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2022-12-26 14:27:21
-->
<template>
  <el-dialog
    :title="title"
    :visible.sync="showModify"
    custom-class="modify-app"
    :lock-scroll="true"
    top="5vh"
    width="80%"
    @close="onClose"
  >
    <el-tabs v-model="activeName" tab-position="left" style="height: 70vh;">
      <el-tab-pane label="基本信息" name="base">
        <div class="type-label">基本信息</div>
        <el-form ref="modifyForm" :model="modifyForm" label-position="top" :rules="rules">
          <el-form-item label="名称" prop="name">
            <el-input v-model="modifyForm.name" placeholder="请输入应用名称" clearable></el-input>
          </el-form-item>
          <el-form-item label="描述" prop="describe">
            <el-input v-model="modifyForm.describe" style="width: 350px" :rows="4" type="textarea" placeholder="请输入应用描述" clearable></el-input>
          </el-form-item>
          <el-form-item label="分类" prop="saas_apps_type">
            <ClassificationSelect v-model="modifyForm.saas_apps_type" ></ClassificationSelect>
          </el-form-item>
          <el-form-item label="附加安装应用">
            <el-select v-model="additionalId" multiple placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
            <el-form-item
              class="form-item-height"
              label="图标"
              prop="icon"
            >
              <el-select :value="other" style="width: 344px;" placeholder="" class="select-icon">
                <template slot="prefix">
                  <div
                    class="header-img"
                    :style="'background: ' + modifyForm.background">
                    <svg aria-hidden="true"
                      class="color-icon">
                      <use :xlink:href="modifyForm.icon.indexOf('#')===-1? '#'+modifyForm.icon : modifyForm.icon" />
                    </svg>
                  </div>
                </template>
                <template #empty>
                  <div class="choose-color" style="width: 344px; padding: 8px;height: 384px">
                    <p>选择颜色</p>
                    <div class="color-container">
                      <div
                        class="color-item"
                        :style="'background:' + item.background"
                        v-for="(item, index) in colorList"
                        style="width: 32px;height: 32px"
                        :key="index"
                        @click="
                          (modifyForm.color = item.color),
                            (modifyForm.background = item.background)
                        "
                      >
                        <i
                          v-if="modifyForm.background === item.background"
                          class="iconfont iconselect-duigou"
                        ></i>
                      </div>
                    </div>
                    <p>选择图形</p>
                    <div
                      class="color-container"
                      style="flex: 1; overflow-y: auto"
                    >
                      <div
                        class="color-item-app"
                        v-for="(icon, index) in appliIcons"
                        :key="index"
                        :class="(modifyForm.icon.includes('#')? modifyForm.icon: '#'+modifyForm.icon) === icon.font_class? 'color-item-app-border' : ''"
                        @click="modifyForm.icon = icon.font_class"
                      >
                      <div  class="color-item color-item-app-div" 
                      :style="modifyForm.background? 'background:'+ modifyForm.background:'background:linear-gradient(180deg, #FFA557 0%, #FF5C5C 100%)'">
                        <svg aria-hidden="true"
                          class="color-icon">
                          <use :xlink:href="icon.font_class" />
                        </svg>
                      </div>
                      </div>
                    </div>
                  </div>
                </template>
              </el-select>
            </el-form-item>
          <!-- </el-form-item> -->
          <!-- 目前权限saas不需要 -->
          <!-- <el-form-item label="权限" prop="auth_key">
            <el-input v-model="modifyForm.auth_key" placeholder="请输入权限字段" clearable></el-input>
          </el-form-item> -->
          <el-form-item label="是否付费" prop="is_pay">
            <el-switch
              v-model="modifyForm.is_pay"
              :active-value="1"
              :inactive-value="0"
              active-text="付费"
              inactive-text="免费">
            </el-switch>
          </el-form-item>
          <el-form-item label="最新上架" prop="is_new">
            <el-switch
              v-model="modifyForm.is_new"
              :active-value="1"
              :inactive-value="0"
              active-text="是"
              inactive-text="否">
            </el-switch>
          </el-form-item>
          <el-form-item label="值得尝试" prop="is_attempt">
            <el-switch
              v-model="modifyForm.is_attempt"
              :active-value="1"
              :inactive-value="0"
              active-text="是"
              inactive-text="否">
            </el-switch>
          </el-form-item>
          <el-form-item label="封面" prop="image">
            <FileUpload :fileTypeList="['png', 'jpg', 'jpeg']" :files="modifyForm.image" @extra-files="(res) => { modifyForm.image = res }" />
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="应用详情" name="info" style="padding-right: 15px">
        <el-tabs v-model="editableTabsValue" class="inner-tabs" type="card" editable style="height: 100%;" @edit="handleTabsEdit">
          <el-tab-pane
            :key="item.name"
            v-for="(item) in modifyForm.saas_apps_detail"
            :label="item.name"
            :name="item.name"
          >
            <RichTextEditor v-model="item.content" />
          </el-tab-pane>
          <el-empty v-if="!modifyForm.saas_apps_detail || modifyForm.saas_apps_detail.length === 0" description="暂无内容"></el-empty>
        </el-tabs>
      </el-tab-pane>
    </el-tabs>
    <div slot="footer" class="dialog-footer">
      <el-button @click="onClose">取 消</el-button>
      <el-button v-show="activeName === 'base'" @click="activeName = 'info'">下 一 页</el-button>
      <el-button v-show="activeName === 'info'" type="primary" @click="onConfirm">提 交</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { dataInterface } from '@/apis/data';
import { Tabs, TabPane, MessageBox, Empty  } from 'element-ui'
import ClassificationSelect from './ClassificationSelect.vue'
import RichTextEditor from './components/RichTextEditor.vue'
import FileUpload from '@/manage-views/components/FileUpload.vue'
import { appliIcons } from '@/manage-views/views/home/components/applicationTree/components/icons';
export default {
  name: 'ModifyApp',
  components: {
    'el-tabs': Tabs,
    'el-tab-pane': TabPane,
    ClassificationSelect,
    RichTextEditor,
    'el-empty': Empty,
    FileUpload
  },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    type: { //类型
      type: Number,
      required: false,
      default: 0 // 0 创建  1 编辑
    },
    // 应用数据
    appData: {
      type: Object,
      required: false,
      default: () => {}
    },
    appList:{
      type: Array,
      default: () =>{ }
    }
  },
  inject: ['DATABASE'],
  data() {
    return {
      appliIcons: appliIcons,
      other: '',
      showModify: false,
      modifyForm: {
        name: '',
        describe: '',
        // large_icon: [],
        icon: '#icon-a-05jiluwendang',
        auth_key: '',
        saas_apps_type: [],
        saas_apps_detail: [],
        is_pay: 0,
        is_new: 0,
        is_attempt: 0,
        image: [],
        background: 'linear-gradient(180deg, #FFA557 0%, #FF5C5C 100%)',
        saas_apps_additional:[]
      },
      colorList: [
        {
          color: '#fb9337',
          background: 'linear-gradient(180deg, #FFA557 0%, #FF5C5C 100%)'
        },
        {
          color: '#fab300',
          background: 'linear-gradient(180deg, #FFCF3D 0%, #FF9A42 100%)'
        },
        {
          color: '#67c200',
          background: 'linear-gradient(180deg, #48E199 0%, #15C2B6 100%)'
        },
        {
          color: '#00bd77',
          background: 'linear-gradient(180deg, #5ADFFA 0%, #459CFF 100%)'
        },
        {
          color: '#00c5fb',
          background: 'linear-gradient(180deg, #8C9FFF 0%, #A970FF 100%)'
        },
      ],
      rules: {
        name: [{ required: true, message: '请输入应用名称', trigger: 'blur' }],
        describe: [{ required: true, message: '请输入应用描述', trigger: 'blur' }],
        auth_key: [{ required: true, message: '请输入应用权限字段', trigger: 'blur' }],
        // large_icon: [{ required: true, message: '请选择封面', type: 'array', trigger: 'change' }],
        icon: [{ required: true, message: '请选择icon', trigger: 'change' }],
        image: [{ required: true, message: '请选择封面', type: 'array', trigger: 'change' }],
        // additionalId:[{ "required": true, "message": "抄送人为必填项", type: 'array', "trigger": "change" }]
      },
      activeName: 'base',
      editableTabsValue: '',
      tabIndex: 0,
      options:[],
      additionalId:[]
    }
  },
  // watch: {
  //   appList:{
  //     handler(val){
  //     console.log(val)
  //     if(this.modifyForm.id){
  //       val.forEach(item=>{
  //           if(item.id !== this.modifyForm.id){
  //             this.options.push(item)
  //           }
  //         })
  //       }else{
  //         this.options = val
  //     }
  //   },
  //   deep:true,
  //   } 
  // },
  computed: {
    title() {
      return this.type === 1 ? '编辑应用' : '创建应用';
    }
  },
  created() {
    if (this.type === 1) {
      for (const key in this.appData) {
        if (Object.hasOwnProperty.call(this.appData, key)) {
          if(key === 'saas_apps_type') {
            this.modifyForm[key] = this.appData[key].map(el => el.type_id)
          } else {
            this.modifyForm[key] = this.appData[key]
          }
        }
      }
      for (let i = 0; i < this.modifyForm.saas_apps_additional.length; i++) {
        this.additionalId.push(this.modifyForm.saas_apps_additional[i].attach_app_id)
      }
      if(Array.isArray(this.modifyForm.saas_apps_detail) && this.modifyForm.saas_apps_detail.length) {
        this.editableTabsValue = this.modifyForm.saas_apps_detail[0].name
      }
      if(!this.modifyForm.background){
        this.modifyForm.background = 'linear-gradient(180deg, #FFA557 0%, #FF5C5C 100%)'
      }
      console.log(this.modifyForm)
    }
    this.showModify = this.value;
  },
  mounted() {
    // 获取list数据来当作下附加安装应用得数据
    dataInterface({
        __method_name__: 'dataList',
        ...this.DATABASE,
        transcode: 0,
        size:9999
    }).then(res => {
      if (res.status === 200) {
        let data = res.data.data.data
        if(this.modifyForm.id){
          data.forEach(item=>{
            if(item.id !== this.modifyForm.id){
              this.options.push(item)
            }
          })
        }else{
          this.options = data
        }
      }
      this.loading = false;
    }).catch(err => {
      console.log(err);
      this.$message.error('获取数据失败！');
    });
  },
  methods: {
    onClose() {
      this.options=[]
      this.additionalId=[]
      this.$emit('input', false);
    },
    handleTabsEdit(targetName, action) {
      if (action === 'add') {
        MessageBox.prompt('请输入名称', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /[\S]+/,
          inputErrorMessage: '不能为空'
        }).then(({ value }) => {
          this.modifyForm.saas_apps_detail.push({
            name: value,
            content: ''
          });
          this.editableTabsValue = this.modifyForm.saas_apps_detail[this.modifyForm.saas_apps_detail.length - 1].name
        }).catch(() => {
          // nothing to do
        })
      }
      if (action === 'remove') {
        MessageBox.confirm('确定删除吗, 是否继续?', '提示', {
          confirmButtonText: '删除',
          cancelButtonText: '取消',
          type: 'error'
        }).then(() => {
          let tabs = this.modifyForm.saas_apps_detail
          let activeName = this.editableTabsValue
          if(activeName === targetName) {
            const index = tabs.findIndex(el => el.name ===  targetName)
            if(index < 0) {
              return
            }
            if(index >= 1) {
              this.editableTabsValue = tabs[index - 1].name
            }
            if(index === 0) {
              if(tabs.length > 1) {
                this.editableTabsValue = tabs[index + 1].name
              } else {
                this.editableTabsValue = ''
              }
            }
          }
          this.modifyForm.saas_apps_detail = tabs.filter(tab => tab.name !== targetName)
        }).catch(() => {
          // nothing to do
        })
      }
    },
    onConfirm() {
      this.$refs.modifyForm.validate(valid => {
        if (!valid) {
          this.$message.error('请完整填写表单');
           return;
        }
        this.modifyForm.saas_apps_additional = []
        this.additionalId.forEach(item=>{
          this.modifyForm.saas_apps_additional.push({
            attach_app_id :item
          })
        })
        console.log(this.modifyForm)
        const loading = this.$loading();
        // 提交操作
        let params = {
          __method_name__: this.type === 0 ? 'createData' : 'updateData',
          ...this.DATABASE,
          data_id: this.type === 1 ? this.appData.id : '',
          name: this.modifyForm.name,
          // large_icon: this.modifyForm.large_icon,
          icon: this.modifyForm.icon,
          describe: this.modifyForm.describe,
          auth_key: this.modifyForm.auth_key || '',
          parent_id: 0,
          level: 1,
          saas_apps_detail: Array.isArray(this.modifyForm.saas_apps_detail) ? this.modifyForm.saas_apps_detail : [],
          saas_apps_type: Array.isArray(this.modifyForm.saas_apps_type) ? this.modifyForm.saas_apps_type.map(el => ({ type_id: el })) : [],
          saas_apps_additional:this.modifyForm.saas_apps_additional,
          is_pay: this.modifyForm.is_pay || 0,
          is_new: this.modifyForm.is_new || 0,
          is_attempt: this.modifyForm.is_attempt || 0,
          background:this.modifyForm.background,
          image: Array.isArray(this.modifyForm.image) ? this.modifyForm.image : [],
        }
        // if(this.modifyForm.saas_apps_additional.length){
        //   params.saas_apps_additional = this.modifyForm.saas_apps_additional
        // }
        dataInterface(params).then(res => {
          if (res.status === 200) {
            this.$message.success('操作成功！');
            this.onClose();
            this.$emit('updateList');
          } else {
            this.$message.error('操作失败！');
          }
          loading.close();
        }).catch(err => {
          console.log(err, '');
          this.$message.success('操作失败！');
          loading.close();
        })
      });
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../css/manageAdd.less";
:deep(.select-icon) {
  .el-input {
    width: 48px;
    height: 48px;
    background: #FFFFFF;
    border-radius: 4px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      display: none;
    }
    .el-input__suffix {
      display: none;
    }
    .el-input__prefix {
      width: 48px;
      height: 48px;
      i {
        font-size: 18px;
        color: #000;
      }
    }
  }
}
:deep(.modify-app) {
  .el-dialog__header{
   text-align: left;
   border-bottom: 1px solid #f5f5f5;
  }
  .el-input, .el-select{
    width: 350px;
  }
  .el-tabs__content{
    height: 100%;
    .el-tab-pane{
      height: 100%;
      overflow-y: auto;
    }
  }
  .inner-tabs{
    display: flex;
    flex-direction: column;
    .el-tabs__header{
      margin: 0;
      border: none;
      .el-tabs__new-tab{
        width:25px;
        height:25px;
        >i{
          font-size: 24px;
          color: black;
        }
      }
    }
    .el-tabs__content{
      flex-grow: 1;
      height: 10px;
      overflow: hidden;
    }
  }
}
.form-item-height {
  width: 100%;
}
.choose-color {
    width: 460px;
    height: 300px;
    display: flex;
    flex-direction: column;
    padding: 10px 0 10px 10px;
    box-sizing: border-box;
    :deep(.el-tree-node__content) {
      height: auto;
      margin-bottom: 4px;
      box-sizing: border-box;
      .el-tree-node__expand-icon, .is-leaf {
        display: none;
      }
    }
    p {
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      color: #121315;
      margin: 16px 0;
    }
    .color-container {
      width: 100%;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      .color-item {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        i {
          color: #fff;
        }
        .color-item-i {
          color: #606266
        }
      }
      .color-item-app {
        width: 46px;
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        margin: 0 6px 6px 0;
        .color-item-app-div{
          width: 40px;
          height: 40px;
          border-radius: 8px;
          margin-right: 0 !important;
        }
        svg {
          width: 30px;
          height: 30px;
          color: #fff;
        }
      }
      .color-item-app-border {
        border: 2px solid #D0E2FF ;
        box-sizing: border-box;
        border-radius: 10px;
      }
    }
    /* 架构选择样式 */
    .archi-label {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        -moz-user-select:none; /* Firefox私有属性 */
        -webkit-user-select:none; /* WebKit内核私有属性 */
        -ms-user-select:none; /* IE私有属性(IE10及以后) */
        -khtml-user-select:none; /* KHTML内核私有属性 */
        -o-user-select:none; /* Opera私有属性 */
        user-select:none; /* CSS3属性 */
        .archi-label-svg {
          width: 100%;
          display: flex;
          align-items: center;
          overflow: hidden;
          span {
            flex: 1;
            overflow: hidden;
            padding-right: 6px;
            box-sizing: border-box;
          }
          img {
            width: 20px;
            height: 20px;
            margin-right: 6px;
          }
          svg {
            width: 20px;
            height: 20px;
            margin-right: 6px;
          }
        }
      }
  }
  .header-img {
    width: 48px;
    height: 48px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 26px;
    }
    svg {
      width: 36px;
      height: 36px;
    }
  }
</style>
