<!--
 * @Description: 应用管理
 * @Author: luocheng
 * @Date: 2022-03-14 10:41:28
 * @LastEditors: zx
 * @LastEditTime: 2022-06-16 11:17:39
-->
<template>
  <div class="application">
    <header class="header">

      <el-menu
        style="width: 100%; display: flex; justify-content: center"
        class="el-menu-demo"
        :default-active="step"
        mode="horizontal"
        @select="handleClick"
      >
        <el-menu-item index="list"> PC应用管理 </el-menu-item>
        <el-menu-item index="mobile"> H5应用管理 </el-menu-item>
      </el-menu>
    </header>
    <article class="content">
      <AppList v-if="step === 'list'"></AppList>
      <MobileConfig v-if="step === 'mobile'"></MobileConfig>
    </article>
  </div>
</template>

<script>
import { Menu, MenuItem } from "element-ui";
import AppList from './AppList';
import MobileConfig from './MobileConfig';

export default {
  name: 'Application',
  components: {
    "el-menu": Menu,
    "el-menu-item": MenuItem,
    AppList,
    MobileConfig
  },
  data() {
    return {
      // list 列表， setUp 配置
      step: 'list',
      currentApp: null
    }
  },
  created() {
    this.step = sessionStorage.getItem('appStepName') || 'list';
    const appCurrentApp = sessionStorage.removeItem('appCurrentApp');
    if (appCurrentApp) {
      this.currentApp = JSON.parse(appCurrentApp);
    }
  },
  methods: {
    /**
     * @desc: 进入配置
     * @param {Object} data 应用
     */
    handleClick(data) {
      this.step = data;
      sessionStorage.setItem('appCurrentApp', data);
      sessionStorage.setItem('appStepName', this.step);
    }
  }
}
</script>

<style lang="less" scoped>
.application{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  .header{
    display: flex;
    border-bottom: 1px solid #f5f5f5;
    box-sizing: border-box;
    cursor: pointer;
    .title{
      flex: 1;
      line-height: 20px;
      font-size: 14px;
      text-align: left;
    }
    .back{
      line-height: 20px;
      font-size: 14px;
      text-align: left;
      box-sizing: border-box;
      display: block;
      margin-right: 10px;
    }
  }
  .content{
    flex: 1;
    width: 100%;
    box-sizing: border-box;
    // padding: 5px;
    background: #f5f5f5;
    overflow: hidden;
  }
}
</style>
